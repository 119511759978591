.Page {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5em;
}

.Page mark {
  background: #fef09f;
  margin: -3px -2px;
  padding: 3px 2px;
}

.Page blockquote {
  margin-inline-start: 0;
  padding-inline-start: 1em;
  border-left: 4px solid lightgray;
}

.Page img {
  max-width: 100%;
}

.Page ul, .Page ol {
  padding-inline-start: 2em;
}

.Page li.task-list-item {
  list-style-type: none;
  margin-inline-start: -1.5em;
}

.Page li.task-list-item > ul, .Page li.task-list-item > ol {
  margin-inline-start: 1.5em;
}

.Page pre {
  white-space: pre-wrap;
}

.Page .para.selected {
  background-color: cornsilk;
}

.Page .para {
  position: relative;
}

.Page .para .id {
  position: absolute;
  left: -50px;
  font-family: monospace;
  opacity: 0.3;
}
.Page .para .id a:visited {
  color: #106ba3;
}
.Page .para .id a:target, .Page .para .id a.css-target /* work around https://github.com/whatwg/html/issues/639 */ {
  background-color: yellow;
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  box-sizing: border-box;
  padding: 2px 8px;
}
.table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.expandingArea {
  position: relative;
  background: transparent;
}
.expandingArea > textarea, .expandingArea > pre {
  background: transparent;
  font: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-rendering: inherit;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}
.expandingArea > textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  resize: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.expandingArea > pre {
  visibility: hidden;
}

a:link {
  text-decoration: none;
  color: #106ba3;
}
a:link:hover {
  text-decoration: underline;
}
a.wikilink:before {
  content: '[[';
  color: #a7b6c2;
}
a.wikilink:after {
  content: ']]';
  color: #a7b6c2;
}
a.wikilink:visited {
  color: #106ba3;
}

.ReplicationStateIndicator {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ReplicationStateIndicator .bubble {
  border-radius: 999px;
  width: 10px;
  height: 10px;
  background-color: red;
}
.ReplicationStateIndicator.behind .bubble {
  background-color: orange;
}
.ReplicationStateIndicator.synced .bubble {
  background-color: green;
}

.modal-backdrop {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}

.Switcher {
  position: absolute;
  top: 20px;
  width: 600px;
  background: white;
}

.Switcher input {
  width: 100%;
  font-size: inherit;
}
